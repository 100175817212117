<template>
	<div class="container">
		<h1>{{ title }}</h1>
		<div class="flex-container">
			<div class="img-container">
				<img :src="imgSrc" alt="Avi Picture" />
			</div>
			<div class="content-container">
				<p class='content-text'>{{ content }}</p>
				<div class='person-desc-container'>
					<p class='person-desc-bold'>{{ name }}</p> 
					<p class='person-desc-light'>{{ ' | ' +  titleSlalom}}</p>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	props: [
		'title',
		'content',
		'imgSrc',
		'name',
		'titleSlalom',
		'companyName',
	],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.container {
	@include padding(5 10 5 10);
	@include responsive(phone) {
		@include padding(5 0 5 0)
    }
    @include responsive(tablet) {
		@include padding(5 5 5 5)
    }
}

.content-container {
	border-left: 5px solid #edf2f4;
    @include margin-left(5);
    @include padding-left(5);
	@include responsive(phone) {
        font-size: 1.8vh;
		@include padding-left(3);
		@include margin-left(3);
    }
}

.name-container {
	@include color(takeda-medium-gray)
}

.content-container {
	font-weight: 400;
}

h1 {
	@include padding-bottom(2);
	@include color(takeda-dark-grey);
	font-size: 24px;
	
}

img {
	border-radius: 50%;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

.person-desc-container {
    display: flex;
    flex-direction: row;
	align-content: center;
}

.person-desc-bold {
    font-size: 18px;
	@include text-bold;
    @include color(takeda-medium-gray);
	@include responsive(phone) {
        font-size: 1.8vh;
    }
}

.person-desc-light {
    font-size: 18px;
    @include color(takeda-medium-gray);
    white-space: pre;
	@include responsive(phone) {
        font-size: 1.8vh;
    }
}
</style>