<template>
  <Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
  <div class="theme-container">
      <PageTitle
        title="Summary of 2021"
        :imgSrc="images['header1.svg']"
        bgColor="#1D63CF"
      ></PageTitle>
      <div class="letter-container">
        <div class="content-wrapper">
          <h2>{{ content['section-1-title'] }}</h2>
          <p>
            {{ content['section-1-paragraph-1'] }}
          </p>
          <p>
            {{ content['section-1-paragraph-2'] }}
          </p>
          <p>
            {{ content['section-1-paragraph-3'] }}
          </p>
          <p>
            {{ content['section-1-paragraph-4'] }}
          </p>
          <p>
            {{ content['section-1-paragraph-5'] }}
          </p>

          <div class="signature-container">
           <Signature
            :imgSrc="images['person1.png']"
            :name="content['section-1-quoter-1-name']"
            :titleSlalom="content['section-1-quoter-1-title-slalom']"
          >
          </Signature>
            <Signature
            :imgSrc="images['person-2.png']"
            :name="content['section-1-quoter-2-name']"
            :titleSlalom="content['section-1-quoter-2-title-slalom']"
            >
            </Signature>
            <Signature
              :imgSrc="images['person-3.png']"
              :name="content['section-1-quoter-4-name']"
              :titleSlalom="content['section-1-quoter-4-title-slalom']"
            >
            </Signature>
            <Signature
              :imgSrc="images['person-4.png']"
              :name="content['section-1-quoter-5-name']"
              :titleSlalom="content['section-1-quoter-5-title-slalom']"
              :companyName="content['section-1-quoter-5-title-client']"
            >
            </Signature>
            <Signature
              :imgSrc="images['person6.png']"
              :name="content['section-1-quoter-6-name']"
              :titleSlalom="content['section-1-quoter-6-title-slalom']"
              :companyName="content['section-1-quoter-6-title-client']"
            >
            </Signature>
            <Signature
              :imgSrc="images['person-7.png']"
              :name="content['section-1-quoter-7-name']"
              :titleSlalom="content['section-1-quoter-7-title-slalom']"
              :companyName="content['section-1-quoter-7-title-client']"
            >
            </Signature>
          </div>
        </div>
        
      </div>
      <div class="card-container">
        <Card
          :title="content['section-2-title']"
          :content="content['section-2-paragraph-1']"
          :optionalContent="[
            content['section-2-paragraph-2']
          ]"
          :imgSrc="images['review.png']"
        >
        </Card>
      </div>

      <div class="quotes-container">
        <h1>{{ content['section-3-title'] }}</h1>
        <div class="quotes-wrapper">
          <QuoteCard
            :title="content['section-3-quote-1-title']"
            :imgSrc="images['quoter1-1-photo.png']"
            :content="content['section-3-quote-1-quote']"
            :name="content['section-3-quote-1-quoter-name']"
            :titleSlalom="content['section-3-quote-1-quoter-jobtitle']"
            :companyName="content['section-3-quote-1-quoter-company']"
          >
          </QuoteCard>

          <QuoteCard
            :title="content['section-3-quote-2-title']"
            :imgSrc="images['quoter2-2-photo.png']"
            :content="content['section-3-quote-2-quote']"
            :name="content['section-3-quote-2-quoter-name']"
            :titleSlalom="content['section-3-quote-2-quoter-jobtitle']"
            :companyName="content['section-3-quote-2-quoter-company']"
          >
          </QuoteCard>

          <QuoteCard
            :title="content['section-3-quote-3-title']"
            :imgSrc="images['quoter3-3-photo.png']"
            :content="content['section-3-quote-3-quote']"
            :name="content['section-3-quote-3-quoter-name']"
            :titleSlalom="content['section-3-quote-3-quoter-jobtitle']"
            :companyName="content['section-3-quote-3-quoter-company']"
          >
          </QuoteCard>
          <QuoteCard
            :title="content['section-3-quote-4-title']"
            :imgSrc="images['quoter-4-photo.png']"
            :content="content['section-3-quote-4-quote']"
            :name="content['section-3-quote-4-quoter-name']"
            :titleSlalom="content['section-3-quote-4-quoter-jobtitle']"
            :companyName="content['section-3-quote-4-quoter-company']"
          >
          </QuoteCard>
          <QuoteCard
            :title="content['section-3-quote-5-title']"
            :imgSrc="images['quoter-5-photo.png']"
            :content="content['section-3-quote-5-quote']"
            :name="content['section-3-quote-5-quoter-name']"
            :titleSlalom="content['section-3-quote-5-quoter-jobtitle']"
            :companyName="content['section-3-quote-5-quoter-company']"
          >
          </QuoteCard>
        </div>
      </div>
  </div>
  <BottomNavigator :order='1'/>
  </Loading>
</template>

<script>
import BottomNavigator from '@/components/BottomNavigator.vue';
import Card from '@/components/Card.vue';
import Loading from '@/components/Loading.vue';
import Navigation from '@/components/Navigation.vue';
import PageTitle from '@/components/PageTitle.vue';
import QuoteCard from '@/components/QuoteCard.vue';
import Signature from '@/components/Signature.vue';
import Alert from '@/components/Alert.vue';
import api from '@/services/api.js';

export default {
	data: () => ({
		content: {},
		images: {},
		isLoading: false,
		isError: false,
		errorMessage: ''
	}),
	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getContent('summary');
				this.content = data.content;
				this.images = data.images;
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		}
	},

	async beforeRouteUpdate(to, from, next) {
		await this.getData();
		next();
	},

	async created() {
		await this.getData();
	},

	components: {
    BottomNavigator,
		PageTitle,
		Loading,
		Navigation,
		Card,
		QuoteCard,
		Signature,
    Alert,
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  @include relative;
  @include margin(0 0 10 0);
  :deep(.page-title) {
    .title-container{
      @include bg-color(takeda-light-pink);
    }
  }
}

.card-container {
  @include padding(18 10 18 10);
    @include responsive(phone) {
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
} 

.signature {
    @include margin-left(5);
    @include padding-left(5);
}

.frame-title {
  @include color(white);
  font-size: 2em;
  @include padding(0 0 0 2);
}

.letter-container {
  @include bg-color(takeda-light-grey);
  @include padding(8);
  @include responsive(phone) {
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.quotes-container {
  @include padding(8 16 8 16);
  @include responsive(phone) {
    @include margin(0 1 0 1);
    @include padding(4 4 4 4);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  h1 {
    @include color(takeda-light-pink);
    font-size: 48px;
    line-height: 120%;
  }
  .quotes-wrapper{
    @include padding(0 10 0 10);
    @include responsive(phone) {
      @include padding(0);
    }
    @include responsive(tablet) {
      @include padding(0);
    }
  }
}

.quote-container p {
  border-left: 5px solid #edf2f4;
  @include padding-left(5);
}



h2 {
  @include margin-bottom(1);
  @include color(takeda-dark-grey);
  border-bottom: none;
  font-size: 28px;
}

p {
  font-weight: normal;
  font-size: 18px;
  @include color(takeda-dark-grey);
}

.content-wrapper {
  @include padding(8);
  @include bg-color(white);
  border-bottom-right-radius: 4em;
  @include responsive(phone) {
    @include margin(0 1 0 1);
    @include padding(4 4 4 4);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.div-wrapper {
  @include padding(8);
}

.img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  @include margin(2);
  @include border-radius(2);
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
}

.signature-container {
  display: block;
}
</style>
